import React from "react"
import { graphql, navigate } from "gatsby"
import { Box, Flex, Heading, Button, Divider, Text } from "@chakra-ui/core"
import { Paragraph, Bold, Illustration } from "../components/ui-elements"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopBlog from "../components/sections/TopBlog"
import FirstBlog from "../components/sections/FirstBlog"
import VirtualAssistant from "./virtual-assistant"

const BlogPage = (props) => {
  const blogs = props.data.allBlogPost.edges;
  const pageInfo = props.data.allBlogPost.pageInfo;

  return (
    <Layout>
      <SEO title="Blog - VERIFiSMART" />      
      {blogs.length === 0 ? <TopBlog /> : <FirstBlog blog={blogs[0].node} /> }
      <Box px="4" py="16" w="100%" maxW="containers.xl" mx="auto">
        <Box p="4" >
          <Heading >Blog Posts</Heading>
          <Divider orientation="horizontal" bg="#39B54A" w="190px" h="0.3rem" opacity={1}/>
        </Box>
        <Flex wrap="wrap">
          {blogs.map((blog, i) => (
              <Flex
                onClick={(e) => { navigate(e.currentTarget.id); }} 
                cursor="pointer"
                id={blog.node.id} 
                direction="column" 
                width="380px" 
                mx="4"
                my="8" 
                key={blog.node.id}>
                  <SEO description={blog.node.description} title={blog.node.title}/>
                  {
                    blog.node.image
                    ? <Illustration src={blog.node.image} w="380px" h="250px" alt={blog.node.title} objectFit="cover" />
                    : <Illustration src="top-background.jpg" w="380px" h="250px" alt="default image" objectFit="cover" /> 
                  }                  
                  <Heading size="lg" fontSize="1.4rem" mt="6" overflow="hidden">
                    {blog.node.title}
                  </Heading>                  
                  <Text overflow="hidden" mt="2">{blog.node.description}</Text>
                  <Flex >
                    <Paragraph mt="2" mb="4">
                      created by
                      <Bold color="theme-green" pl="4">{blog.node.author}</Bold> 
                    </Paragraph>
                    <Paragraph mt="2" mb="4" px="2" color="theme-green">|</Paragraph>
                    <Paragraph mt="2" mb="4">
                      {new Date (blog.node.createdAt).toDateString().slice(4)}
                    </Paragraph>
                  </Flex>
              </Flex>
          ))}          
        </Flex>       
      </Box>
      <Flex alignItems="center" justifyContent="center">
        {(pageInfo.currentPage > pageInfo.pageCount) 
          ? <Button color="white" bg="theme-green" _hover={{ bg: "theme-purple" }}>
              Previous
            </Button>
          : <Box w="100px"></Box>
        }
        <Box m="8">{pageInfo.currentPage}</Box>
        { 
          (pageInfo.currentPage < pageInfo.pageCount) 
          &&  <Button w="100px" color="white" bg="theme-green" _hover={{ bg: "theme-purple" }}>
                Next
              </Button>}
      </Flex>
      <VirtualAssistant />
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
query AllBlogIndexQuery {
    allBlogPost(sort: {fields: createdAt, order: DESC}) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        totalCount
        pageCount
        perPage
        itemCount
      }
      edges {
        node {
          author
          body
          createdAt
          description
          id
          image
          title
        }
      }
    }
  }
`
