import React from "react"
import { Link } from "gatsby"
import { Flex, Box, Heading, Text, Button } from "@chakra-ui/core"
import { Paragraph, Bold } from "../../components/ui-elements"
import topBackground from "../../images/top-background.jpg"

const FirstBlog = ({blog}) => {

  return (
    <Box id="top-section">
      <Flex
        height= "calc(100vh - 100px)"         
        background={ blog.image 
          ? `url(${blog.image}) center/cover no-repeat`
          : `url(${topBackground}) center/cover no-repeat`
        }
        alignItems="center"
        justify="center"
      >
        <Box position="absolute" w="100%" height="calc(100vh - 100px)" background="rgba(0, 0, 0, 0.3)">
          <Flex
            alignItems="center"
            justify="center"
            maxW="containers.xl"
            mx="auto"
            minH="calc(100vh - 100px)"
            color="white"
            px="4"
            flexDir="column"
            w={["100%", "100%", "60%"]} 
          >            
            <Flex mt="16" flexDir="column" justify="center" >
              <Box>
                <Heading size="2xl" fontWeight="200">
                  {blog.title}
                </Heading>
              </Box>
              <Flex >
              <Paragraph >
                created by
                <Bold color="theme-green" pl="4">{blog.author}</Bold> 
              </Paragraph>
              <Paragraph px="8" color="theme-green">|</Paragraph>
              <Paragraph >{new Date (blog.createdAt).toDateString().slice(4)}</Paragraph>
              </Flex>
              <Text fontSize="xl">
                {blog.description}
              </Text>
            </Flex>
            <Flex
              mt="16"
              color="white"
              height="100px"
              justify="center"
              align="center"
            >
              <Button as={Link} to={blog.id} id={blog.id} bg="theme-green" _hover={{ bg: "theme-purple" }} onClick={(e) => console.log(e.target.id)} >
                Read More
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default FirstBlog;
